<template>
  <v-content>
    <v-btn
      :loading="loading"
      @click="generatePDF()"
    >
      imprimir
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table>
  </v-content>
</template>
<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  data() {
    return {
      loading: false,
      generalBalance: null,
      desserts: [],
      headers: [
        {
          text: 'Cliente',
          align: 'start',
          sortable: false,
          value: 'client',
        },
        { text: 'Descripcion', value: 'description' },
        { text: 'Entrada', value: 'amountIn' },
        { text: 'Salida', value: 'amountOut' },
        { text: 'Saldo', value: 'balance' },
        { text: 'Fecha', value: 'date' },
      ],
    }
  },
  mounted() {
    this.getReport()
  },
  methods: {
    generatePDF() {
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     //format: [4, 2],
    //     format: "A4",
    //     margin: { top: 20 },
    //   });
      const doc = new jsPDF('p', 'pt')

      const logo = require('../../assets/images/logos/efectivo-ya-png.png')

      const imgLogo = new Image()
      imgLogo.src = logo
      doc.addImage(imgLogo, 'PNG', 40, 40, 80, 40)

      const arregloDatos = Object(this.desserts).map(obj => {
        const datos = [
          obj.client,
          obj.description,
          obj.amountIn,
          obj.amountOut,
          obj.balance,
          obj.date,
        ]

        return datos
      })

      const encabezado = ['Cliente', 'Descripcion', 'Entrada', 'Salida', 'Saldo', 'Fecha']

      const titulo = 'Listado de Caja diario'
      doc.text(titulo, doc.internal.pageSize.width * 0.40, 80, {
        aling: 'right',
      })

      const emisor = `Emitido por ${this.$store.getters.usuario.name}`

      // const lineaInicial = 70;

      //   const lineaInicialSombreado = 80;

      const espacioEntreLineas = 20

      const fechaEmision = `Fecha de Emision: ${this.moment().format('DD/MM/YYYY')}`

      //   doc.setFillColor(240, 240, 240);
      //   doc.rect(
      //     doc.internal.pageSize.width * 0.05,
      //     lineaInicialSombreado,
      //     46,
      //     13,
      //     "F"
      //   );

      doc.text(
        fechaEmision,
        doc.internal.pageSize.width * 0.05,
        100 + espacioEntreLineas * 1,
        {
          aling: 'right',
        },
      )

      doc.text(
        emisor,
        doc.internal.pageSize.width * 0.05,
        115 + espacioEntreLineas * 1,
        {
          aling: 'right',
        },
      )

      doc.autoTable({
        // html: '#my-table',
        head: [
          ['', 'Efectivo', 'Transferencia', 'Bienes'],
        ],
        body: [
          ['Entrada', `$ ${this.generalBalance.amountIn}`, '$ 0', '$ 0'],
          ['Salida', `$ ${this.generalBalance.amountOut}`, '$ 0', '$ 0'],
          ['Saldo', `$ ${this.generalBalance.balance}`, '$ 0', '$ 0'],

        ],
        columnStyles: {
          1: { halign: 'right' },
          2: { halign: 'right' },
          3: { halign: 'right' },

        // etc
        },
        headerStyles: { halign: 'center' },
        theme: 'grid',
        tableWidth: 515,
        aling: 'right',
        margin: { top: 200 },

      })
      autoTable(doc, {
        head: [encabezado],
        headerStyles: { halign: 'center' },

        // theme: 'grid',
        body: arregloDatos,
        tableWidth: 515,
        columnStyles: {
          0: { cellWidth: 120 },
          1: { cellWidth: 120 },
          2: { cellWidth: 60, halign: 'right' },
          3: { cellWidth: 60, halign: 'right' },
          4: { cellWidth: 80, halign: 'right' },
          5: { cellWidth: 90, halign: 'right' },

        // etc
        },
      })

      doc.save('nombreArchivo.pdf', false)
    },
    async getReport() {
      this.loading = true
      this.url = `${this.$store.getters.urlBase}/api/Report/cashRegisterOperationWithBalance`
      const options = {
        headers: {
          'content-type': 'application/json',
        },
        params: {
          fromDate: null, // this.moment().format('YYYY-MM-DD'),
          toDate: null, // this.moment().format('YYYY-MM-DD'),
        },

        url: this.url,
      }
      await axios
        .get(this.url, options)
      // eslint-disable-next-line no-return-assign
        .then(response => {
          this.desserts = response.data.cashRegisterOperations
          this.generalBalance = response.data
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
  },
}
</script>
